.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.logo {
  background: url('assets/img/logo.svg') no-repeat;
  width: 90px;
  height: 25px;
}

.loading-container {
  background-color: #efefef;
  height:100vh;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  color: #c0c0c0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.brand-footer a {
  text-decoration: none;
  color: #000;
}

.btn-find {
  background-color: transparent !important;
  color: #fff !important;
  font-weight: bold !important;
}

.padding-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.list {
  padding-left: 10px;
  
}

.btn:focus {
  box-shadow: none !important;
}

input[type="text"]:focus {
  box-shadow: none !important;
}

ul li.location-row.active {
  border-left: 5px solid #c0c0c0;
}

.location-dot-solid {
  background-image: url('assets/img/location-dot-solid.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  color: #dadada;
}

@media only screen and (max-width: 600px) {
  .list {
    overflow-y: auto;
    height: auto;
  }

  .list ul {
    display: table;
  }

  /* .list ul li {
    display: table-row;
  } */
}

@media only screen and (min-width: 600px) {
  .list {
    height: 480px;
    overflow-y: scroll;
  }
}


.list ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  cursor: pointer;
}

.list ul li {
  text-align: left;
  margin-left: 0;
  padding: 10px;
  padding-left: 10px !important;
  height: 100px;
  justify-content: center;
  flex: 1;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #efefef;
}

.list ul li:hover {
  background-color: #efefef;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.beacon {
  position:absolute;
  top:50%;
  left:50%;
  height:2em;
  width:2em;
  border-radius:50%;
  border-width: 4px;
  border-color: #fff;
  border-style: solid;
  -webkit-animation: grow .4s 1 linear;
  animation: grow .4s 1 linear;
  -webkit-transform:translateX(-50%) translateY(-50%);
  z-index: 5;
  cursor: pointer;
}
.beacon:before {
  position:absolute;
  content:"";
  height:2em;
  width:2em;
  left:-4px;
  top:-4px;
  background-color:transparent;
  border-radius:50%;
  -webkit-animation:active 2s infinite linear;
  animation:active 2s infinite linear;
}
.detail-marker {
  display:inline-block;
  position:relative;
  border-bottom:1px dotted #666;
  text-align:left;
  z-index: 99; 
}

.detail-marker a {
  text-decoration: none;
  color: #414141;
}

.detail-marker .icon {
  background: url('assets/img/cs.png') no-repeat center;
  background-size: 110%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-width: 4px;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  top: -15px;
  /* left: 12px; */
  left: 50%;
  transform:translate(-50%, 0%);
}
.detail-marker h5{
  font-size: 18px;
  font-weight: 800;
}
.detail-marker .close {
  position: absolute;
  right: 12px;
  top: 8px;
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: #9e9e9e;
}
.detail-marker .top {
  min-width:220px; 
  top:-30px;
  left:50%;
  transform:translate(-50%, -100%);
  padding:20px 20px;
  padding-top: 45px;
  color:#444444;
  background-color:#FFFFFF;
  font-weight:normal;
  font-size:13px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.detail-marker .top i {
  position:absolute;
  top:100%;
  left:50%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.detail-marker .top i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#FFFFFF;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  /* margin: 8px; */
  border: 4px solid #c0c0c0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #c0c0c0 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(.1);
  }
  
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes grow {
  0% {
    transform: scale(.1);
  }
  
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes active{
  0%{
    -webkit-transform:scale(.1);
    opacity:1;
  }
  70%{
    -webkit-transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}

@keyframes active{
  0%{
    transform:scale(.1);
    opacity:1;
  }
  70%{
    transform:scale(2.5);
    opacity:0;
  }
  100%{
    opacity:0;
  }
}
